<template>
  <div>
    <TableUpdateRequest
      ref="tableUpdateRequest"
      :allfilters="DataFilters"
      :fields="inProcessFields"
      :params="setParams"
    >
      <template #buttons>
        <div>
          <b-button
            v-if="isSpecialist && hasPermission"
            @click="assignUpdate"
            class="ml-2"
            variant="outline-success"
            :disabled="isLoading"
          >
            <span v-if="isLoading">
              Assigning...<b-spinner small variant="success" />
            </span>
            <span v-else>Assign Update</span>
          </b-button>
        </div>
      </template>
      <template #lead_name="data">
        <span class="d-flex align-items-center" style="gap: 4px">
          <router-link
            v-if="moduleId != 22"
            :to="accessElementTable.getToParams(data.item.account_id)"
            target="_blank"
          >
            {{ data.item.client_name }}
          </router-link>

          <router-link
            v-if="getRouterLink(data.item.account_id).validModuleId"
            :to="getRouterLink(data.item.account_id).to"
          >
            {{ data.item.client_name }}
          </router-link>
          <b-badge
            class="font10 ml-1"
            :variant="
              data.item.name_type_client == 'DIGITAL' ? 'info' : 'primary'
            "
            v-if="[6, 20, 21, 22].includes(data.item.module_id)"
          >
            {{ data.item.name_type_client }}
          </b-badge>
        </span>
        <status-account :account="data.item" :text="true" />
        <div class="d-flex align-items-center">
          <span class="d-block">
            {{ data.item.client_account }}
          </span>
        </div>
        <b-badge
          v-if="data.item.status == 'RETURNED'"
          variant="warning"
          class="ml-1"
          >{{ data.item.status }}</b-badge
        >
      </template>
      <template #title="data">
        <div
          class="text-primary cursor-pointer font-weight-bolder"
          @click="openModalAddRound(data.item)"
        >
          {{ [1, 2].includes(data.item.type_round) ? "FIRST ROUND" : "-" }}
        </div>
      </template>
      <template #last_score_date="data">
        {{ data.item.last_score_date | myGlobalDay }}
        <div>
          <b-badge
            :variant="
              validateDate(data.item.last_score_date)
                ? 'light-success'
                : 'light-danger'
            "
            >{{
              validateDate(data.item.last_score_date)
                ? "CR CURRENT"
                : "CR EXPIRED"
            }}</b-badge
          >
        </div>
      </template>
      <template #step="{ item }">
        <div class="d-flex flex-column align-items-center">
          <amg-icon
            @click="openModalSteps(item)"
            size="25"
            :icon="steps.icons[item.current_step - 1]"
            class="text-primary cursor-pointer"
          />
          <b-badge variant="dark">{{
            steps.step[item.current_step - 1]
          }}</b-badge>
        </div>
      </template>
      <template #user_created="data">
        <b-col>
          <b-row
            ><span> {{ data.item.user_created }} </span></b-row
          >
          <b-row
            ><span>{{
              data.item.create_dispute | myGlobalWithHour
            }}</span></b-row
          >
        </b-col>
      </template>
      <template #specialist_assign="{ item }">
        <div v-if="isSpecialist">
          <feather-icon
            v-if="!item.specialist_assign"
            @click="openModalAssign(item)"
            size="16"
            icon="UserPlusIcon"
            class="mr-50 text-success cursor-pointer"
          />
          <span v-if="item.specialist_assign" class="d-block">{{
            item.specialist_assign
          }}</span>
        </div>
        <div v-else>
          <span v-if="item.specialist_assign">{{
            item.specialist_assign
          }}</span>
          <span v-else>-</span>
        </div>
      </template>
      <template #type="data">
        <b-badge
          class="w-100 text-center d-flex justify-content-center"
          :variant="data.item.type == 1 ? 'light-success' : 'light-warning'"
        >
          {{ data.item.type == 1 ? "LETTER" : "RECOVERY" }}
        </b-badge>
      </template>
      <template #update_file="{ item }">
        <template v-for="(data, index) in JSON.parse(item.files)">
          <a
            :key="index"
            :href="data.url"
            target="_blank"
            v-b-tooltip.hover.top="data.file_name"
          >
            <amg-icon
              :key="index"
              icon="FilePdfIcon"
              style="height: 18px; width: 18px; margin-bottom: 3px"
              class="cursor-pointer mt-0 mr-0 bigger text-danger text-center"
          /></a>
        </template>
      </template>
      <template #request="{ item }">
        <div class="text-center">
          <feather-icon
            v-if="moduleId == 28 && !validateDate(item.last_score_date)"
            icon="PlusCircleIcon"
            size="18"
            class="cursor-pointer text-info ml-1"
            variant="info"
            @click="createReport(item)"
          />
          <feather-icon
            icon="TrashIcon"
            size="18"
            class="cursor-pointer text-danger ml-1"
            variant="danger"
            @click="deletedUpdateRequest(item.id)"
          />
        </div>
      </template>
    </TableUpdateRequest>
    <AssignSpecificModal
      v-if="modalAssign.show"
      v-bind="modalAssignScope.attr"
      v-on="modalAssignScope.events"
    />
  </div>
</template>
<script>
import { inProcessFields } from "@/views/commons/components/dispute-results/views/data/fields.data";
import DataFilters from "@/views/specialist-digital/views/updates/data/filters.data.js";
import TableUpdateRequest from "@/views/commons/components/dispute-results/views/components/TableUpdateRequest.vue";
import { mapGetters } from "vuex";
import StatusAccount from "@/views/commons/components/clients/stylescomponents/StatusAccount.vue";
import RequestWorkPlanService from "@/views/commons/components/request-workplan/services/request-workplan.service.js";
import AssignSpecificModal from "@/views/commons/components/request-workplan/views/components/modal/AssignSpecificModal.vue";
import updateRequestService from "@/views/commons/components/dispute-results/services/dispute-results.service.js";
import UpdateService from "@/views/specialist-digital/views/updates/services/update.service.js";

export default {
  name: "TableInProcess",
  components: {
    TableUpdateRequest,
    StatusAccount,
    AssignSpecificModal,
  },
  data() {
    return {
      inProcessFields,
      DataFilters,
      isLoading: false,
      modalAssign: {
        show: false,
        item: null,
        section: null,
      },
    };
  },
  computed: {
    ...mapGetters({
      currentUser: "auth/currentUser",
    }),
    modalAssignScope() {
      const { item, section } = this.modalAssign;
      return {
        attr: {
          item,
          section,
        },
        events: {
          close: () => {
            this.modalAssign.show = false;
            this.modalAssign.item = null;
            this.modalAssign.section = null;
          },
          refresh: this.refreshTable,
        },
      };
    },
    setParams() {
      return {
        orderby: "create_dispute",
        status: 1,
        specialist_id: this.isCreditAnalyst
          ? this.currentUser.user_id
          : this.getFilterByKey("specialist").model,
      };
    },
    isCreditAnalyst() {
      return this.currentUser.role_id == 22;
    },
    moduleId() {
      return this.$route.matched[0].meta.module;
    },
    steps() {
      return this.$refs.tableUpdateRequest.getSteps();
    },
    isSpecialist() {
      return this.moduleId === 28;
    },
    hasPermission() {
      return (
        this.isCeo || this.isChief || this.isSupervisor || this.isAnalystLeader
      );
    },
    accessElementTable() {
      return this.$refs.tableUpdateRequest;
    },
  },
  methods: {
    async getCreditAnalyst() {
      let result = [];
      // result = await UpdateService.getUsersProgramsspNcr(params); Service Old
      result = await UpdateService.getCreditAnalyst();
      this.users = result.data;
      let newList = this.users.map(
        (s) => (
          { label: "All", value: 0 }, { label: s.specialist, value: s.id }
        )
      );
      newList.splice(0, 0, { label: "All", value: 0 }); //add position 0
      this.getFilterByKey("specialist").options = newList;
    },
    start() {
      this.$nextTick(async () => {
        let table = this.$refs.tableUpdateRequest;
        table.filterColumn("cr", this.isSpecialist);
        table.filterColumn("step", this.isSpecialist);
        table.filterColumn("specialist_assign", this.hasPermission);
        table.filterColumn("process", this.isSpecialist);
        table.filterColumn("request", this.isSpecialist);
        await this.getCreditAnalyst();
      });
    },
    refreshTable() {
      this.$refs.tableUpdateRequest.refreshTable();
    },
    async assignUpdate() {
      this.isLoading = true;
      const params = {
        section: "UPDATE_REQUEST",
      };
      const data = await RequestWorkPlanService.assignWorkplan(params);
      if (data.status == 200) {
        this.isLoading = false;
        this.refreshTable();
      }
    },
    getFilterByKey(key) {
      return this.DataFilters.find((filter) => filter.key === `${key}`);
    },
    openModalAddRound(data) {
      this.$refs.tableUpdateRequest.openModalAddRound(data);
    },
    validateDate(date) {
      let currentDate = new Date();
      let enteredDate = new Date(date);
      let monthValidateNcr = 5;

      currentDate.setMonth(currentDate.getMonth() - monthValidateNcr);
      this.isValid = enteredDate >= currentDate;
      return this.isValid;
    },
    async openModalSteps(item) {
      this.$refs.tableUpdateRequest.openModalSteps(item);
    },
    openModalAssign(item) {
      this.modalAssign.item = item;
      this.modalAssign.section = "UPDATE_REQUEST";
      this.modalAssign.show = true;
    },
    async createReport(item) {
      const confirm = await this.showConfirmSwal(
        "You want to create a NCR?",
        "If you are sure, click on the confirm button"
      );
      if (!confirm.isConfirmed) return;
      this.addPreloader();
      try {
        const params = {
          client_account_id: item.client_account_id,
          round_letter_dispute_id: item.id,
          lead_id: item.lead_id,
        };
        await updateRequestService.storeRequestNcrForUpdate(params);
        this.showSuccessSwal();
      } catch (error) {
        this.showErrorSwal(error);
      } finally {
        this.removePreloader();
        this.$nextTick(() => {
          this.refreshTable();
        });
      }
    },
    async deletedUpdateRequest(id) {
      try {
        const result = await this.showConfirmSwal();
        if (result.isConfirmed) {
          const params = {
            id_dispute: id,
            user_id: this.currentUser.user_id,
          };
          const response = await updateRequestService.deletedUpdateRequest(
            params
          );
          if (response.status === 200) {
            this.showSuccessSwal();
            this.refreshTable();
          }
        }
      } catch (error) {
        console.error("Error tring to delete a Update Request");
      }
    },
    getRouterLink(client_account_id) {
      return this.accessElementTable.getRouterLink(client_account_id);
    },
  },
  mounted() {
    this.start();
  },
};
</script>
